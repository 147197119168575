import React, {Component} from 'react';
import { compose, downloadTextAsFile } from '../../../utils'
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {Col, Form, Container, Row, Button, Alert} from "react-bootstrap";
import Dayjs from "dayjs"

import { eachWeekOfInterval, eachMonthOfInterval, eachQuarterOfInterval, eachYearOfInterval, getWeek, startOfWeek, endOfWeek,
  startOfMonth, endOfMonth, startOfQuarter, endOfQuarter, startOfYear, endOfYear, format, parseISO, isValid } from 'date-fns';
import { translate } from 'jsoneditor/src/js/i18n'

const formatDateGroupLabel = (date, groupBy) => {
  switch (groupBy) {
    case '1':
      return `${date.getFullYear()}-W${getWeek(date)}`;
    case '2':
      return `${date.getFullYear()}-${date.getMonth() + 1}`;
    case '3':
      return `${date.getFullYear()}-Q${Math.floor(date.getMonth() / 3) + 1}`;
    case '4':
      return `${date.getFullYear()}`;
    default:
      return '';
  }
}

const groupDates = (startDate, endDate, groupBy) => {
  const interval = { start: new Date(startDate), end: new Date(endDate) };
  let periods = [];

  switch (groupBy) {
    case '1':
      periods = eachWeekOfInterval(interval).map(date => ({
        start: startOfWeek(date),
        end: endOfWeek(date)
      }));
      break;
    case '2':
      periods = eachMonthOfInterval(interval).map(date => ({
        start: startOfMonth(date),
        end: endOfMonth(date)
      }));
      break;
    case '3':
      periods = eachQuarterOfInterval(interval).map(date => ({
        start: startOfQuarter(date),
        end: endOfQuarter(date)
      }));
      break;
    case '4':
      periods = eachYearOfInterval(interval).map(date => ({
        start: startOfYear(date),
        end: endOfYear(date)
      }));
      break;
    default:
      console.error('Unsupported groupBy value');
      return;
  }

  return periods.map(period => ({
    label: formatDateGroupLabel(period.start, groupBy),
    start: period.start,
    end: period.end
  }));

};

const groupUGC = (companies, user_groups, users, groupBy) => {
  let result = []
  switch (groupBy) {
    case '1':
      result = companies.map(c => ({
        name: c.name,
        company_id: c.id
      }));
      break;
    case '2':
      result = user_groups.map(g => ({
        name: g.name,
        user_group_id: g.id
      }));
      break;
    case '3':
      result = users.map(u => ({
        name: u.user_name,
        user_id: u.id
      }));
      break;
    default:
      console.error('Unsupported groupBy UGC');
      return;
  }

  return result.map(res => ({
    ...res,
    label: res.name,

  }));
}

class Reports extends Component {
  state = {
    report_type: '',
    createTimeTo: '',
    createTimeFrom: '',
    result: '',
    companies: [],
    user_groups: [],
    users: [],
    submitted: false,
    company_id: 0,
    user_group_id: 0,
    showPicker: false,
    dateInput: 'createTimeFrom',
    group_by_date: '2',
    group_by_ugc: '0',
    colName: 'General',
    error: ''
  };

  filterType = {
    analysisCount: {start: 'POST /benchmark/',},
    loginCount: {start: 'POST /user/login :'},
    nnTotalCount: {start: 'POST /net :'},
    nnLongSentencesCount: {start: 'POST /net :', sub: '"request":"moreSentencesInWords_suggestions_de"'},
    nnComplexSentenceCount: {start: 'POST /net :', sub: '"request":"moreSentencesInClauses_suggestions_de"'},
    nnPassiveVoiceCount: {start: 'POST /net :', sub: '"request":"countPassiveVoiceInSentence_suggestions_de"'},
    aiRedirectAll: {start: 'POST /redirect/'},
    aiRedirectAssistant: {start: 'POST /redirect/Assistant :'},
    aiRedirectSummUsage: {start: 'POST /redirect/SummUsage :'},

  };

  handleChange = (event) => {
    const {name, value} = event.target;
    this.setState({[name]: value});
    this.setState({submitted: false});
  };

  handleSubmit = async (event) => {
    const {userData: {token}, tlService} = this.props;
    event.preventDefault();

    if (this.state.report_type === '') {
      this.setState({error: 'report_invalid_report_type'})
      return;
    }

    if (this.state.createTimeFrom.length > 0) {
      if (!isValid(parseISO(this.state.createTimeFrom))) {
        this.setState({ error: 'report_invalid_date_from' })
        return;
      }
    }

    if (this.state.createTimeTo.length > 0) {
      if (!isValid(parseISO(this.state.createTimeTo))) {
        this.setState({ error: 'report_invalid_date_to' })
        return;
      }
    }

    if (this.state.group_by_date === '0' && this.state.group_by_ugc === '0') {
      this.setState({ error: 'report_date_range_or_ugc_is_required' })
      return;
    }

    if (this.state.group_by_date > '0' && (this.state.createTimeFrom.length ===0 || this.state.createTimeTo.length ===0)) {
      this.setState({ error: 'report_date_from_and_date_to_are_required' })
      return;
    }

    if (this.state.group_by_ugc > '0') {
      if (this.state.group_by_ugc === '1' && this.state.company_id > 0) {
        this.setState({ error: 'report_selected_company_with_group_by_company' })
        return;
      }

      if (this.state.group_by_ugc === '2' && this.state.user_group_id > 0) {
        this.setState({ error: 'report_selected_group_with_group_by_group' })
        return;
      }

      if (this.state.group_by_ugc === '2' && this.state.user_groups.length === 0) {
        this.setState({ error: 'report_select_company_for_group_by_usergroups' })
        return;
      }

      if (this.state.group_by_ugc === '3' && this.state.users.length === 0) {
        this.setState({ error: 'report_select_usergroup_for_group_by_users' })
        return;
      }
    }

    if (this.state.createTimeFrom.length > 0 && this.state.createTimeTo.length > 0 && this.state.createTimeTo <= this.state.createTimeFrom ) {
      this.setState({ error: 'report_date_to_should_be_bigger' })
      return;
    }

    let groupedData = [];
    if (this.state.group_by_date > 0) {
      groupedData = groupDates(this.state.createTimeFrom, this.state.createTimeTo, this.state.group_by_date);
    } else if (this.state.group_by_ugc > 0) {
      groupedData = groupUGC(this.state.companies, this.state.user_groups, this.state.users, this.state.group_by_ugc);
    } else {
      console.log('check10, no group', this.state)
    }

    let result = []

    for (let item of groupedData) {
      let extended_filter = []

      if (this.filterType[this.state.report_type].start !== undefined) {
        extended_filter.push(['|log','l=' + this.filterType[this.state.report_type].start + '%'])
        extended_filter.push(['|log','l=' + this.filterType[this.state.report_type].start.replace(/^(POST|GET|PUT|DELETE) \//, '$1 /api/') + '%'])
      }

      if (this.filterType[this.state.report_type].end !== undefined)
        extended_filter.push(['&log','l=%' + this.filterType[this.state.report_type].end])

      if (this.filterType[this.state.report_type].sub !== undefined)
        extended_filter.push(['&log','l=%' + this.filterType[this.state.report_type].sub + '%'])

      if (this.state.company_id > 0)
        extended_filter.push(['&company_id','=' + this.state.company_id])

      if (this.state.user_group_id > 0)
        extended_filter.push(['&user_group_id','=' + this.state.user_group_id])

      if (this.state.group_by_date > 0) {
        extended_filter.push(['&create_time','>=' + format(item.start, 'yyyy-MM-dd hh:mm:ss')])
        extended_filter.push(['&create_time','<=' + format(item.end, 'yyyy-MM-dd hh:mm:ss')])
      } else if (this.state.group_by_ugc > 0) {

        if (this.state.createTimeFrom ) {
          const startDate = new Date(this.state.createTimeFrom);
          startDate.setHours(0,0,0);
          extended_filter.push(['&create_time', '>=' + format(startDate, 'yyyy-MM-dd hh:mm:ss')])
        }

        if (this.state.createTimeTo ) {
          const endDate = new Date(this.state.createTimeTo);
          endDate.setHours(0,0,0);
          extended_filter.push(['&create_time', '<=' + format(endDate, 'yyyy-MM-dd hh:mm:ss')])
        }

        if (this.state.group_by_ugc === '1')
          extended_filter.push(['&company_id', '=' + item.company_id])
        else if (this.state.group_by_ugc === '2')
          extended_filter.push(['&user_group_id', '=' + item.user_group_id])
        else if (this.state.group_by_ugc === '3')
          extended_filter.push(['&user_id', '=' + item.user_id])
      }

      let count_ = 0;
      try {
        const countData = await tlService.getFilteredLogs(token, {limit: 1}, {extended_filter: JSON.stringify(extended_filter)})
        if (countData['fullcount'] > 0)
          count_ = countData['fullcount'];

      } catch (e) {
      }

      result.push({[item.label]: count_})
    }

    const csvContent = [this.state.colName + ",Count"];
    result.forEach(item => {
      const key = Object.keys(item)[0]; // Get the month
      const value = item[key]; // Get the value
      csvContent.push(`${key},${value}`);
    });
    const csvResult = csvContent.join('\n');
    downloadTextAsFile( translate(this.state.report_type) + '.csv', csvResult)
  }

  handleChangeCompany = async (event) => {
    const {value} = event.target;
    if (value > 0) {
      const { tlService, userData: {token}  } = this.props;
      try {
        const user_groups_ = await tlService.getFilteredGroups(token,{},{company_id: value, order_by: 'name'})
        if (user_groups_.data) {
          this.setState({user_groups: user_groups_.data, company_id: value, submitted: false})
        }

        const users_ = await tlService.getFilteredUsers(token,{},{company_id: value, order_by: 'user_name'})
        if (users_.data) {
          this.setState({users: users_.data, company_id: value, submitted: false})
        }
      } catch (e) {
        console.log("check8 error", e);
      }
    } else {
      this.setState({company_id: 0, user_group_id: 0, user_groups: [], users:[], group_by_ugc: 0})
    }

  }

  handleChangeGroup = async (event) => {
    const {value} = event.target;
    if (value > 0) {
      this.setState({user_group_id: value, });
      const { tlService, userData: {token}  } = this.props;
      try {
        const users_ = await tlService.getFilteredUsers(token,{},{user_group_id: value, order_by: 'user_name'})
        if (users_.data) {
          this.setState({users: users_.data, user_group_id: value, submitted: false})
        }
      } catch (e) {
        console.log("check9 error", e);
      }
    } else {
      this.setState({user_group_id: 0, users: []});
    }

    // console.log("check4", value);
  }

  handleChangeType = async (event) => {
    const { name, value } = event.target;
    if (name === 'group_by_ugc') {
      const ar = ['General','Company','User Group','User']
      this.setState({group_by_date: '0', group_by_ugc: value, colName: ar[value]})
    } else if (name === 'group_by_date') {
      const ar = ['General','Week','Month','Quarter', 'Year']
      this.setState({group_by_date: value, group_by_ugc: '0', colName: ar[value]})
    } else {
      this.setState({[name]: value})
    }

  }

  fetchData = async (tlService, token) => {
    try {
      const companies_ = await tlService.getFilteredCompanies(token,{}, {'order_by':'name'})
      if (companies_.data.length > 0) {
        this.setState({companies: companies_.data, submitted: false});
      }
    } catch (e) {
      console.log("error" + e);
      this.setState({companies: [], submitted: false});

    }
  }

  loadLocale = async () => {
    try {
      const localeName = "de"
      await import(`dayjs/locale/${localeName}`)
      Dayjs.locale(localeName)
    } catch (error) {
      console.error("Error loading locale:", error)
    }
  }

  componentDidMount() {
    const {
      tlService, userData: {token}  } = this.props;
    console.log("componentDidMount");

    this.fetchData(tlService, token);

    this.loadLocale()

  }

  render () {

    const { t: translate } = this.props;

    const {company_id, companies, user_group_id, user_groups} = this.state;
    return(<Container className="h-100 d-inline-block" fluid>
      {
        this.state.error.length > 0 && (<Alert variant={"danger"} dismissible onClose={() => { this.setState({error: ''})}}>{
          this.state.error.length > 0 ? translate(this.state.error) : ''
        }</Alert>)
      }
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">{translate('report_header_title_report')}</h1>
      </div>
      <Row>
        <Col><h4>{translate('report_header_filters')}</h4></Col>
      </Row>
      <Form
        name="filrer reports"
        onSubmit={this.handleSubmit}>
        <Row>
          <Col className={'col-md-2 p-1'}>{translate('report_filter_type')}</Col>
          <Col className={'col-md-9 p-1'}>
            <Form.Control
              size="md"
              as="select"
              value={this.state.report_type}
              name={"report_type"}
              onChange={e => this.handleChangeType(e)}>
              <option key="0" value="0">{translate('report_filter_type_list')}</option>
              {
                Object.keys(this.filterType).map((key) => {
                  return (<option key={key} value={key}>{translate('filter_type_' + key)}</option>)
                })
              }
            </Form.Control>

          </Col>
        </Row>
        <Row>
          <Col className={'col-md-2 p-1'}>{translate('report_filter_companies')}</Col>
          <Col className={'col-md-9 p-1'}>
            <Form.Control
              size="md"
              onChange={e => this.handleChangeCompany(e)}
              value={company_id}
              name="company_id"
              as="select">
              <option key="0" value="0">{translate('report_filter_company_list')}</option>
              {
                Object.keys(companies).length > 0 && Object.keys(companies).map((key) =>
                  <option key={companies[key].id} value={companies[key].id}>{companies[key].name}</option>
                )
              }
            </Form.Control>

          </Col>
        </Row>
        <Row>
          <Col className={'col-md-2 p-1'}>{translate('report_filter_group')}</Col>
          <Col className={'col-md-9 p-1'}>
            <Form.Control
              size="md"
              onChange={e => this.handleChangeGroup(e)}
              value={user_group_id}
              name="user_group_id"
              as="select">
              <option key="0" value="0">{translate('report_filter_group_list')}</option>
              {
                Object.keys(user_groups).length > 0 && Object.keys(user_groups).map((key) =>
                  <option key={user_groups[key].id} value={user_groups[key].id}>{user_groups[key].name}</option>
                )
              }
            </Form.Control>

          </Col>
        </Row>
        <Row>
          <Col className={'col-md-2 p-1'}>{translate('report_filter_date_from')}</Col>
          <Col className={'col-md-9 p-1'}><Form.Control
            value={this.state.createTimeFrom}
            onChange={this.handleChange}
            onClick={() => { this.setState({showPicker: true, dateInput: 'createTimeFrom'}) }}
            name="createTimeFrom"
            type="text"
            placeholder={translate('report_filter_date_from')}/></Col>
        </Row>
        <Row>
          <Col className={'col-md-2 p-1'}>{translate('report_filter_date_to')}</Col>
          <Col className={'col-md-9 p-1'}><Form.Control
            value={this.state.createTimeTo}
            onChange={this.handleChange}
            onClick={() => { this.setState({showPicker: true, dateInput: 'createTimeTo'}) }}
            name="createTimeTo"
            type="text"
            placeholder={translate('report_filter_date_to')}/></Col>
        </Row>
        <Row>
          <Col className={'col-md-2 p-1'}>{translate('report_filter_group_date')}</Col>
          <Col className={'col-md-9 p-1'}>
            <Form.Control
              size="md"
              as="select"
              value={this.state.group_by_date}
              name={'group_by_date'}
              onChange={e => this.handleChangeType(e)}>
              <option key={0} value={0}>{translate('report_filter_group_date_none')}</option>
              <option key={1} value={1}>{translate('report_filter_group_date_week')}</option>
              <option key={2} value={2}>{translate('report_filter_group_date_month')}</option>
              <option key={3} value={3}>{translate('report_filter_group_date_quarter')}</option>
              <option key={4} value={4}>{translate('report_filter_group_date_year')}</option>
            </Form.Control>

          </Col>
        </Row>
        <Row>
          <Col className={'col-md-2 p-1'}>{translate('report_filter_group_ugc')}</Col>
          <Col className={'col-md-9 p-1'}>
            <Form.Control
              size="md"
              as="select"
              value={this.state.group_by_ugc}
              name={'group_by_ugc'}
              onChange={e => this.handleChangeType(e)}>
              <option key={0} value={0}>{translate('report_filter_group_ugc_none')}</option>
              <option key={1} value={1}>{translate('report_filter_group_ugc_company')}</option>
              <option key={2} value={2}>{translate('report_filter_group_ugc_group')}</option>
              <option key={3} value={3}>{translate('report_filter_group_ugc_user')}</option>
            </Form.Control>

          </Col>
        </Row>
        <Row>
          <Col className={'col-md-2 p-1'}>
            <Button
              type="submit"
              className="btn btn-primary btn-user btn-block">
              {translate('report_filter_show')}
            </Button>
          </Col>
        </Row>
      </Form>
      { /*
        this.state.showPicker && <MuiPickersUtilsProvider utils={DayJsUtils}>
          <KeyboardDatePicker
            id="date-picker-dialog"
            // label={translate(this.state.dateInput)}
            format="YYYY-MM-DD"
            inputVariant="outlined"
            fullWidth

            value={this.state[this.state.dateInput]}
            onChange={() => {}}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider> */
      }

      {this.state.result &&
        <Row>
          <Col className={'p-1'}>
            <Alert variant={'primary'}><div dangerouslySetInnerHTML={{__html: this.state.result}}/></Alert>
          </Col>
        </Row>
      }
    </Container>)
  }



}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
  return {userData, openData, modalData, adminData};
};

export default compose(
  withTranslation(),
  withTLService(),
  connect(mapStateToProps)
)(Reports);